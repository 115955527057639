<template>
    <div>
        <!--begin::customer-->
        <b-card no-body class="mb-5">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter') }}
                </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">
                            <div class="form-group col-md-6">
                                <label for="name">{{$t('sales_by_client_report.customer')}}</label>
                              <multiselect v-model="customer"
                                           :placeholder="$t('sales_by_client_report.customer')"
                                           label="fullname"
                                           track-by="id"
                                           :options="customers"
                                           :multiple="false"
                                           :taggable="false"
                                           :show-labels="false"
                                           :show-no-options="false"
                                           :show-no-results="false"
                                           @search-change="getCustomers($event)">
                              </multiselect>
                            </div>
                            <div class="form-group col-md-6">
                                <label>{{$t('customers.countries')}}</label>
                                <div class="input-group">
                                    <!-- <select name="" id="countries" v-model="filters.country" class="custom-select">
                                        <option v-for="row in countries" :value="row.code2" :key="row.id">
                                            {{ row.name }}
                                        </option>
                                    </select> -->
                                    <multiselect v-model="country"
                                                 :placeholder="$t('customers.countries')"
                                                 label="name" track-by="code2"
                                                 :options="countries"
                                                 :multiple="false"
                                                 :taggable="false"
                                                 :show-labels="false"
                                                 :show-no-options="false"
                                                 :show-no-results="false">
                                    </multiselect>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label>{{ $t('users.cities') }}</label>
                                <div class="input-group">
                                    <!-- <select v-model="filters.city" class="custom-select">
                                        <option v-for="row in cities" :value="row.id" :key="row.id">
                                            {{ row.name }}
                                        </option>
                                    </select> -->
                                    <multiselect v-model="city"
                                                 :placeholder="$t('users.cities')"
                                                 label="name" track-by="id"
                                                 :options="cities"
                                                 :multiple="false"
                                                 :taggable="true"
                                                 :show-labels="false"
                                                 :show-no-options="false"
                                                 :show-no-results="false">
                                    </multiselect>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="category">{{$t('category')}}</label>
                                <!-- <select id="category" v-model="filters.category" class="custom-select">
                                    <option v-for="row in categories" :value="row.name" :key="row.id">
                                        {{ row.name }}
                                    </option>
                                </select> -->
                                <multiselect v-model="category"
                                             :placeholder="$t('category')"
                                             label="name" track-by="id"
                                             :options="categories"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false">
                                </multiselect>
                            </div>
                          <div class="form-group col-md-6">
                            <label for="from_date">{{$t('sales_by_client_report.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                          </div>
                          <div class="form-group col-md-6">
                            <label for="to_date">{{$t('sales_by_client_report.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                          </div>
                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>
        <div class="card card-custom">
            <div class="card-body">

                <!-- Filter -->
                <div>
                    <div class="row justify-content-end">
                        <div class="col-12 mt-10 d-flex justify-content-end mb-5">
                            <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                <download-excel
                                        class="dropdown-item"
                                        :fetch="dataReport"
                                        :fields="json_fields"
                                        :name="$t('client_reports.clients_list')+'.xls'">
                                    <i class="la la-file-excel"></i>{{$t('excel')}}
                                </download-excel>
<!--                                <button class="dropdown-item" @click="dataReport">-->
<!--                                    <i class="la la-file-excel"></i>{{$t('excel')}}-->
<!--                                </button>-->
                                <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                                    <i class="fa fa-print"></i> {{$t('print')}}
                                </button>
                                <button class="dropdown-item" @click="printData('summaryTable', 'pdf')">
                                    <i class="fa fa-file-pdf"></i> {{$t('export_pdf')}}
                                </button>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
                <!-- End Filter -->

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table" id="clientReportsTable">

                    <!-- <template slot="category" slot-scope="props">
                        <span v-for="row in props.row.category" :key="row.id" class="badge badge-secondary ml-1">
                            {{row.name}}
                        </span>
                    </template> -->

                    <template slot="classification" slot-scope="props">
                        <span v-if="props.row.classification && props.row.classification.name">{{props.row.classification.name}}</span>
                    </template>
                    <template slot="customer_name" slot-scope="props">
                      <span style="word-wrap: break-word;width: 285px;display: block;">{{ props.row.customer_name }}</span>
                    </template>
                    <template slot="debit" slot-scope="props">
                      <span>{{ props.row.debit }} {{props.row.currency_code}}</span>
                    </template>
                    <template slot="credit" slot-scope="props">
                      <span>{{ props.row.credit }} {{props.row.currency_code}}</span>
                    </template>

                </v-server-table>

            </div>
        </div>
      <div class="drowing-table">
        <table class="table table-bordered" id="summaryTable">
          <thead>
          <tr id="myRow">
            <th>#</th>
            <th>{{$t('customers.full_name')}}</th>
            <th>{{$t('customers.code')}}</th>
            <th>{{$t('customers.classification')}}</th>
            <th>{{$t('customers.debit')}}</th>
            <th>{{$t('customers.credit')}}</th>
            <th>{{$t('created_at')}}</th>
            <th>{{$t('customers.mobile')}}</th>
            <th>{{$t('customers.state')}}</th>
            <th>{{$t('customers.address')}}</th>
            <th>{{$t('customers.phone')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row2, index2) in dataList" :key="'c'+index2">
            <td>{{row2.id}}</td>
            <td>{{row2.customer_name}}</td>
            <td>{{row2.customer_code}}</td>
            <td>{{ (row2.classification && row2.classification.name) ? row2.classification.name : ''}}</td>
            <td>{{row2.debit}} {{row2.currency_code}}</td>
            <td>{{row2.credit}} {{row2.currency_code}}</td>
            <td>{{row2.created_at}}</td>
            <td>{{row2.mobile}}</td>
            <td>{{row2.state}}</td>
            <td>{{row2.address}}</td>
            <td>{{row2.phone}}</td>
          </tr>

          </tbody>
        </table>
      </div>
      <div class="selector-export" id="selectorExport"></div>
        <!--end::customer-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import TableToExcel from "@linways/table-to-excel";
    import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";


    export default {
        name: "index-customers",
        components: {},
        data() {
            return {
                mainRoute: '/reports/customer-report',
              mainRouteExport: '/reports/customer-report',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,
                dataList: [],
                filter: {
                    sortBy: 'id',
                },
                filters: {
                  customer_id: null,
                    category: '',
                    country: 'SA',
                    city: '',
                    state: '',
                    from_date: null,
                    to_date: null,
                },
                columns: ['id', 'customer_code', 'customer_name','classification','debit','credit','created_at', 'state', 'address', 'phone', 'mobile'],
                data: [],
                countries: [],
                category: null,
                categories: [],
                cities: [],
                country: null,
                city: null,
              customers: [],
              customer: null,

            }
        },
        watch: {
            "filters.country": function (val) {
                if (val) {
                    this.getCities(val);
                }
            },
            country: function (val) {
                if (val) {
                    this.filters.country = val.code2;
                } else {
                    this.filters.country = '';
                }
            },
            city: function (val) {
                if (val) {
                    this.filters.city = val.id;
                } else {
                    this.filters.city = '';
                }
            },
            category: function (val) {
                if (val) {
                    this.filters.category = val.id;
                } else {
                    this.filters.category = '';
                }
            },
          customer: function (val) {
            if (val) {
              this.filters.customer_id = val.id;
            } else {
              this.filters.customer_id = null;
            }
          },
        },
        computed: {
              json_fields: function () {
                  let fields = {};
                fields[this.$t('#')] = 'id';
                fields[this.$t('customers.full_name')] = 'customer_name';
                fields[this.$t('customers.code')] = 'customer_code';
                fields[this.$t('customers.categories')] = 'category';
                fields[this.$t('customers.debit')] = 'debit';
                fields[this.$t('customers.credit')] = 'credit';
                fields[this.$t('created_at')] = 'created_at';
                fields[this.$t('customers.mobile')] = 'mobile';
                fields[this.$t('customers.state')] = 'state';
                fields[this.$t('customers.address')] = 'address';
                fields[this.$t('customers.phone')] = 'phone';
                  return fields;
              },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        id: that.$t('#'),
                        customer_name: that.$t('customers.full_name'),
                        customer_code: that.$t('customers.code'),
                        // category: that.$t('customers.categories'),
                        classification: that.$t('customers.classification'),
                        debit: that.$t('customers.debit'),
                        credit: that.$t('customers.credit'),
                        created_at: that.$t('created_at'),
                        mobile: that.$t('customers.mobile'),
                        state: that.$t('customers.state'),
                        address: that.$t('customers.address'),
                        phone: that.$t('customers.phone'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        // created() {
        //     ApiService.get('customers').then(response => {
        //         this.data = response.data.data.data;
        //     }).catch(e => {
        //         console.log(e);
        //     });
        // },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.clients_reports"),route:'/clients/reports'}, {title: this.$t("MENU.clients_reports")}]);

            if (this.filters.name) {
                this.showAdvancedSearch = true;
            }
            this.getCategories();
            this.getCountries();
        },
        methods: {
            dataReport() {
                this.setReportLog('excel', 'clients reports');
                // TableToExcel.convert(document.getElementById("clientReportsTable"), {
                //     name: this.$t('client_reports.clients_list') + '.xlsx',
                //     sheet: {
                //         name: 'Sheet 1'
                //     }
                // });
              return this.getDataList();
            },
          async getDataList() {
            // this.setReportLog('excel', 'clients reports');
            return await ApiService.get(this.mainRouteExport, {params: {...this.filters, is_export: 1}}).then(response => {
              this.dataList = response.data.data;
              return response.data.data;
            }).catch((error) => {
              this.$errorAlert(error);
            });
          },
          printData(tableId, type) {
            this.setReportLog('pdf', 'clients reports');
            let promise = this.getDataList();
            Promise.all([promise]).then(()=>{
              exportPDFInnerAction(tableId , type, this.$t('client_reports.clients_list'), this.$t('client_reports.clients_list'));
            })

          },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.customer_id = '';
                this.filters.category = '';
                this.filters.country = '';
                this.filters.city = '';
                this.filters.state = '';
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.category = null;
                this.customer = null;
                this.country = null;
                this.city = null;
                this.category = null;
                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            getCountries() {
              ApiService.get(this.mainRouteDependency + "/countries").then((response) => {
                  this.countries = response.data.data;
                  if(!this.country)
                    this.country = response.data.data.find(row => row.code2 == 'SA');
              });
            },

            getCities(id) {
                ApiService.get(`base/dependency/cities/${id}`).then((response) => {
                    this.cities = response.data.data;
                });
            },
            getCategories() {
                ApiService.get('base/dependency/customer-classifications').then(response => {
                    this.categories = response.data.data;
                })
            },
          async getCustomers(filter) {
            if(filter && filter.length >= 3)
              await ApiService.get(this.mainRouteDependency + "/customers", {params:{filter: filter}}).then((response) => {
                this.customers = response.data.data;
              });
          },
          /**
           * register log
           */
          setReportLog(key, description){
            ApiService.post("reports/system_activity_log/log_type",{
              key:key,
              description: description,
            });
          }
        },
    };
</script>
<style>
.html2pdf__overlay{
  opacity: 0 !important;
  z-index: -1000;

}
.drowing-table, .selector-export{
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}

</style>